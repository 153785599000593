@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Shade&family=Cabin+Sketch:wght@700&family=Cinzel+Decorative:wght@700&family=Creepster&family=Gloria+Hallelujah&family=Poppins:wght@500&family=Rock+3D&family=Rubik+Beastly&family=Rye&family=Shizuru&family=Twinkle+Star&display=swap');

* {
  box-sizing: border-box;
}

body {
  background-image:url("https://i.postimg.cc/MpXCz3Dh/pexels-laura-james-6101969.jpg");
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  font-family:'Cabin Sketch', cursive;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}


h1 {
  font-size: 11rem;
  margin-top:20rem;
  font-weight: bolder;
  margin-right:2rem;
  color: rgb(235, 14, 69);
  text-shadow: crimson;


}

.countdown-container {
  display:flex;
  margin-right:20rem;
  flex-wrap: wrap;
  justify-content: center;
}

.big-text{
  font-size: 6rem;
  line-height:0.8;
  margin:-1rem 3rem 0rem 2rem;
  font-weight: bold;
  color:rgb(235, 14, 69);

}

.countdowm-el{
  text-align:center;
}
.countdown-el span{
  font-size: 2.5rem;
  color:rgb(235, 14, 69); ;
}

.penguin {

  /* Only change code below this line */
  --penguin-skin: black;
  --penguin-belly: gray;
  --penguin-beak: yellow;
  /* Only change code above this line */

  position: relative;
  margin:-15rem 1rem 1rem 35rem;
  display: block;
  width: 300px;
  height: 300px;
}

.penguin-top {
  top: 10%;
  left: 25%;
  background: var(--penguin-skin, gray);
  width: 50%;
  height: 45%;
  border-radius: 70% 70% 60% 60%;
}

.penguin-bottom {
  top: 40%;
  left: 23.5%;
  background: var(--penguin-skin, gray);
  width: 53%;
  height: 45%;
  border-radius: 70% 70% 100% 100%;
}

.right-hand {
  top: 0%;
  left: -5%;
  background: var(--penguin-skin, gray);
  width: 30%;
  height: 60%;
  border-radius: 30% 30% 120% 30%;
  transform: rotate(90deg);
  z-index: -1;
}

.left-hand {
  top: 0%;
  left: 75%;
  background: var(--penguin-skin, gray);
  width: 30%;
  height: 60%;
  border-radius: 30% 30% 30% 120%;
  transform: rotate(-45deg);
  z-index: -1;
}

.right-cheek {
  top: 15%;
  left: 35%;
  background: var(--penguin-belly, white);
  width: 60%;
  height: 70%;
  border-radius: 70% 70% 60% 60%;
}

.left-cheek {
  top: 15%;
  left: 5%;
  background: var(--penguin-belly, white);
  width: 60%;
  height: 70%;
  border-radius: 70% 70% 60% 60%;
}

.belly {
  top: 60%;
  left: 2.5%;
  background: var(--penguin-belly, white);
  width: 95%;
  height: 100%;
  border-radius: 120% 120% 100% 100%;
}

.right-feet {
  top: 85%;
  left: 60%;
  background: var(--penguin-beak, orange);
  width: 15%;
  height: 30%;
  border-radius: 50% 50% 50% 50%;
  transform: rotate(-80deg);
  z-index: -2222;
}

.left-feet {
  top: 85%;
  left: 25%;
  background: var(--penguin-beak, orange);
  width: 15%;
  height: 30%;
  border-radius: 50% 50% 50% 50%;
  transform: rotate(80deg);
  z-index: -2222;
}

.right-eye {
  top: 45%;
  left: 60%;
  background: black;
  width: 15%;
  height: 17%;
  border-radius: 50%;
}

.left-eye {
  top: 45%;
  left: 25%;
  background: black;
  width: 15%;
  height: 17%;
  border-radius: 50%;
}

.sparkle {
  top: 25%;
  left: 15%;
  background: white;
  width: 35%;
  height: 35%;
  border-radius: 50%;
}

.blush-right {
  top: 65%;
  left: 15%;
  background: pink;
  width: 15%;
  height: 10%;
  border-radius: 50%;
}

.blush-left {
  top: 65%;
  left: 70%;
  background: pink;
  width: 15%;
  height: 10%;
  border-radius: 50%;
}

.beak-top {
  top: 60%;
  left: 40%;
  background: var(--penguin-beak, orange);
  width: 20%;
  height: 10%;
  border-radius: 50%;
}

.beak-bottom {
  top: 65%;
  left: 42%;
  background: var(--penguin-beak, orange);
  width: 16%;
  height: 10%;
  border-radius: 50%;
}



#drawing_canvas {
  position: absolute;
  margin: auto;
  width: 512px;
  height: 350px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.signature {
  margin: 10px auto;
  padding: 10px 0;
  width: 100%;
}

.signature p{
  text-align: center;
  font-family: Helvetica, Arial, Sans-Serif;
  font-size: 0.85em;
  color: #AAAAAA;
}

.signature .much-heart{
  display: inline-block;
  position: relative;
  margin: 0 4px;
  height: 10px;
  width: 10px;
  background: #AC1D3F;
  border-radius: 4px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signature .much-heart::before,
.signature .much-heart::after {
  display: block;
  content: '';
  position: absolute;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #AC1D3F;
  top: -4px;
}

.signature .much-heart::after {
  bottom: 0;
  top: auto;
  left: -4px;
}

.signature a {
  color: #AAAAAA;
  text-decoration: none;
  font-weight: bold;
}
